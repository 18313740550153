
import React from 'react';
import {
	match,
	withRouter
} from 'react-router-dom';
import * as H from 'history';
// import SwiperBlock from './swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, EffectFade } from "swiper";

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import bg_intro1 from '../../static/pics/bg/bg-lg-left-1.svg';
import bg_intro2 from '../../static/pics/bg/bg-lg-left-2.svg';
import slide_intro1 from '../../static/pics/intro/1.jpg';
import slide_intro2 from '../../static/pics/intro/2.jpg';
import slide_intro3 from '../../static/pics/intro/3.jpg';

type MainPageProps = {
	store                 : any,
	match                 : match;
	location              : H.Location,
	history               : H.History,
}
type MainPageState = {
}

class MainPage extends React.Component<MainPageProps, MainPageState> {

	store                 : any;

	render() {

		return (
			<main className="s-main">
				<h1 className="visually-hidden">SoulBound Tokens for all</h1>
				<section className="sec-intro" id="intro">
					<img className="sec-bg bg-left d-none d-xl-block" src={ bg_intro1 } alt="" />
					<p className="btn-top"><a className="btn btn-lg" href="https://appv1.envelop.is/" target="_blank">Create SoulBound Token</a></p>
					<div className="container">
						<div className="intro__img">
							<Swiper
							  autoplay={{
							  	"delay": 2500,
							  	"disableOnInteraction": false
							  }}
							  spaceBetween={0}
							  slidesPerView={1}
							  modules={[Autoplay,EffectFade]}
							  effect="fade"
							>
								<SwiperSlide><img src={ slide_intro1 } className="swiper-slide" /></SwiperSlide>
								<SwiperSlide><img src={ slide_intro2 } className="swiper-slide" /></SwiperSlide>
								<SwiperSlide><img src={ slide_intro3 } className="swiper-slide" /></SwiperSlide>
							</Swiper>
						</div>
						<div className="intro__text">
							<h1>SoulBound Token</h1>
							<p>SoulBound Tokens <em>or Souls</em> are non-transferable tokens that were proposed by V. Buterin, Puja Ohlhaver and E. Glen Weyl in theirs <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4105763" target="_blank">Decentralized Society: Finding Web3's Soul</a> paper in May 2022.</p>
							<p>The purpose of this token is to create another instrument of transactional reputation, along with retrospective tokens or voting power in Decentraland. SoulBound Token identifies not just the account owner, but his skills, experience, participation in various ratings and other useful activities.</p>
						</div>
					</div>
				</section>
				<div className="divider left"></div>
				<section className="sec-special" id="special"><img className="sec-bg bg-left d-none d-xl-block" src={ bg_intro2 } alt="" />
				  <div className="container">
				  	<div className="special__text">
				  		<h2>How to create SBT with ENVELOP</h2>
						<p>Although the name SoulBound Token appeared not so long ago, ENVELOP approached the creation of this tool independently and ahead of time:</p>
						<p>On our application page <a href="https://appv1.envelop.is/" target="_blank">appv1.envelop.is</a> you can <strong>create an SBT as non-transferable wrapped NFT in 721 or 1155 standards</strong>.</p>
						<p>At the same time, you can also order a <strong>customized SBT development</strong> of such application if you want to use SoulBound Token in your project.</p>
						<p>&nbsp;</p>
						<p><a className="btn btn-lg" href="https://appv1.envelop.is/" target="_blank">Create SoulBound Token</a></p>
						<p>&nbsp;</p>
						<p><strong>ATTENTION! Disclaimer: You must understand that this tool is provided "as is", that is, all possible errors and shortcomings are your responsibility. In addition, this tool is created using blockchain technology, which we have no influence on.</strong></p>
						<p>&nbsp;</p>
						<p>If you want to get a more customized interaction with the tool, then please contact us by email: <a href="mailto:info@envelop.is" target="_blank">info@envelop.is</a>, as well as through the public telegram channel: <a href="https://t.me/envelop_en" target="_blank">t.me/envelop_en</a>. If you want to learn more about DAO ENVELOP projects, then refer to the documentation: <a href="https://docs.envelop.is" target="_blank">docs.envelop.is</a></p>
				    </div>
				  </div>
				</section>
			</main>
		)
	}
}

export default withRouter(MainPage);